<template>
  <div class="public-home">
    <!-- Hero -->
    <div class="hero">
      <b-container>
        <b-row>
          <b-col align-self="center">
            <h1>WGAW {{ settings.electionYear }} Elections</h1>
            <p>
              Welcome to the {{ settings.electionYear }} WGAW Candidates page.
              Below is the list of Board candidates running in this year’s
              election. If a candidate has created a campaign website, their
              name will appear in blue and link to their site. This page will be
              updated with new links, when available, until voting closes on
              {{ lastDayOfVoting | formatDate }} at
              {{ lastDayOfVoting | formatTime }} PST.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container>
      <!-- ---------------------- Officiers ---------------------- -->
      <div class="category" v-if="officers.length > 0">
        <b-row>
          <b-col>
            <h2 class="title">Officers</h2>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <b-col md="6" lg="3" v-for="item in officers" :key="item.id">

            <b-link :class="item.isPublished ? '' : 'disabled'" target="_blank"
              :href="item.isPublished ? getLiveSiteUrl(item) : '#'">
              <b-card>
                <b-img class="avatar" rounded="circle" alt="Name" :src="item.headshotUrl
                                  ? item.headshotUrl
                                  : require('@/assets/images/public/home/avatar/default.gif')
                                  " fluid></b-img>
                <div class="name">
                  {{ item.name }}
                </div>
                <div class="position">{{ item.position }}</div>
                <div class="contact" v-if="item.isPublished">
                  <b-badge variant="success" :href="getLiveSiteUrl(item)">Website</b-badge>
                </div>
              </b-card>
            </b-link>
          </b-col>
        </b-row>
      </div>

      <!-- ---------------------- Board of Directors ---------------------- -->
      <div class="category" v-if="boardMembers.length > 0">
        <b-row>
          <b-col>
            <h2 class="title">Board of Directors</h2>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <b-col md="6" lg="3" v-for="item in boardMembers" :key="item.id">
            <b-link :class="item.isPublished ? '' : 'disabled'" target="_blank"
              :href="item.isPublished ? getLiveSiteUrl(item) : '#'">
              <b-card>
                <b-img class="avatar" rounded="circle" alt="Name" :src="item.headshotUrl
                                  ? item.headshotUrl
                                  : require('@/assets/images/public/home/avatar/default.gif')
                                  " fluid></b-img>
                <div class="name">
                  {{ item.name }}
                </div>
                <div class="position">{{ item.position }}</div>
                <div class="contact" v-if="item.isPublished">
                  <b-badge variant="success" :href="getLiveSiteUrl(item)">Website</b-badge>
                </div>
              </b-card>
            </b-link>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "home",
  async mounted() {
    await this.$store.dispatch("settings/getSettings");
    await this.$store.dispatch("home/getBoardMembers");
    await this.$store.dispatch("home/getOfficers");
  },
  computed: {
    settings() {
      return this.$store.state.settings.item;
    },
    lastDayOfVoting() {
      return new Date(this.settings.lastDateOfVoting);
    },
    officers() {
      return this.$store.state.home.officers;
    },
    boardMembers() {
      return this.$store.state.home.boardMembers;
    },
  },
  methods: {
    getLiveSiteUrl(item) {
      if (item.useInternalAccount) {
        return `${process.env.FRONT_END}${item.liveSiteUrlPath}`;
      } else {
        return item.externalUrl;
      }
    },
    getHeadshotUrl(item) {
      if (item.headshotUrl) {
        return item.headshotUrl;
      }
      return `require('./assets/images/public/avatar/default.gif')`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/public/home/_base.scss";

a.disabled {
  pointer-events: none;
}
</style>
